<script lang="ts" setup>
import { addTeacherRoleToStudentMutation } from '~/graphql/documents/core';
import { useMutation } from '@urql/vue';
import type { FormResult } from '@year13/ui';

const { globalTeacherRole } = useAuth();
const { clusterDomain } = useDomainConfig();
const { cluster } = useConfigStore();
const { t } = useI18n();

const result = ref<FormResult | null>(null);

const { executeMutation: addTeacherRoleToStudent, fetching: loading } = useMutation(addTeacherRoleToStudentMutation);
const addTeacherRole = async () => {
  result.value = null;
  const { error, data } = await addTeacherRoleToStudent({});
  if (error) {
    result.value = { variant: 'error', message: getFirstErrorMessageFromCombinedErrors(t, error) };
    return;
  }

  if (data?.core.user.addTeacherRoleToStudent?.success) {
    await navigateTo(`//schools.${clusterDomain}/dashboard`, { external: true });
  }
};
</script>

<template>
  <div class="flex items-center justify-center px-2" data-testid="manage-acccess-banner">
    <div class="flex w-full flex-col rounded-lg bg-secondary-50 p-4 text-primary-500">
      <div class="flex w-full flex-col space-y-3">
        <div class="flex items-center">
          <UIAssetIcon path="info" size="sm" class="mb-0 mr-1 flex" />
          <span class="text-sm">
            {{
              !globalTeacherRole
                ? $t('general.accessManage.signUpTeacher.label')
                : $t('general.accessManage.switchSite.label', {
                    manageProductName: cluster?.manageProductName || '',
                  })
            }}
          </span>
        </div>
        <UIButton
          v-if="!!globalTeacherRole"
          :text="$t('general.accessManage.switchSite.buttonLabel', { manageProductName: cluster?.manageProductName || '' })"
          size="xs"
          variant="primary"
          customClasses="text-white"
          :to="`//schools.${clusterDomain}/dashboard`"
        />
        <UIButton
          v-else
          :loading="loading"
          :text="$t('general.accessManage.signUpTeacher.buttonLabel')"
          size="xs"
          variant="primary"
          customClasses="text-white"
          @click="addTeacherRole"
        />
      </div>
      <UITransitionFadeIn>
        <p v-if="result" class="mt-1 text-sm font-semibold text-red-500">
          {{ result.message }}
        </p>
      </UITransitionFadeIn>
    </div>
  </div>
</template>
