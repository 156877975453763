import { type Client, createClient, fetchExchange, type SSRData, ssrExchange, mapExchange, subscriptionExchange } from '@urql/core';
import { cacheExchange } from '@urql/exchange-graphcache';
import { devtoolsExchange } from '@urql/devtools';
import { defineNuxtPlugin } from '#app';
import schema from '@/graphql/generated/introspection.json';
import {
  RespondStudentRoleRequestStatus,
  RoleInviteStatus,
  type BulkRespondStudentRoleRequestsInput,
  type SchoolMutations,
  type SchoolStudentRoleInviteFieldsFragment,
} from '~/graphql/generated/graphql';
import { getSelfWallet } from '~/graphql/documents/wallet';
import { selfQuery } from '~/graphql/documents/core';

const CACHE_KEY = '__URQL_DATA__';

const GQL_OP_STREAMING_SET = new Set<number>();

export default defineNuxtPlugin({
  name: 'urql',
  dependsOn: [],
  setup: (nuxtApp) => {
    const ssrHeaders = useRequestHeaders(['cookie', 'user-agent']);

    const { apiUrl, siteDomain } = useDomainConfig();
    const { forwardSubscriptionToPusher } = useSubscriptionExchange();

    const _ssrExchange = ssrExchange({
      isClient: import.meta.client,
    });

    // ssr data in nuxt state
    const ssrData = useState<SSRData>(CACHE_KEY);

    // restore SSR state from nuxt payload
    if (import.meta.client) {
      _ssrExchange.restoreData(ssrData.value);
    }

    // when app has rendered in server, send SSR state to client
    if (import.meta.server) {
      nuxtApp.hook('app:rendered', () => {
        ssrData.value = _ssrExchange.extractData();
      });
    }
    interface CacheKeyMap {
      [key: string]: (data: never) => string | null;
    }
    const urqlClient = createClient({
      url: `${apiUrl}/graphql`,
      exchanges: [
        devtoolsExchange,
        cacheExchange({
          schema,
          keys: new Proxy<CacheKeyMap>(
            {
              // UserProfileSummary: (data) => data.weirdId,
            },
            {
              get: (target, prop: string | symbol) => {
                if (typeof prop === 'string' && prop in target) {
                  return target[prop];
                }
                // @ts-expect-error: Proxy fallback for unknown cache key structure
                return (data) => data?.id || null;
              },
            },
          ),
          updates: {
            Mutation: {
              completeActivity(result, _args, cache) {
                // @ts-expect-error urql-internal-logic
                if (result?.completeActivity?.updatedUserActivityGroups) {
                  cache.updateQuery({ query: selfQuery }, (data) => {
                    // @ts-expect-error urql-internal-logic
                    if (!data?.core.user.self?.userActivityGroups) {
                      return null;
                    }
                    // @ts-expect-error urql-internal-logic
                    data.core.user.self.userActivityGroups = result.completeActivity.updatedUserActivityGroups;
                    return data;
                  });
                }

                // @ts-expect-error urql-internal-logic
                if (result?.completeActivity?.updatedUserWalletItemValues) {
                  cache.updateQuery({ query: getSelfWallet }, (data) => {
                    if (!data?.core.user.self?.walletItemValues) {
                      return null;
                    }
                    // @ts-expect-error urql-internal-logic
                    data.core.user.self.walletItemValues = result.completeActivity.updatedUserWalletItemValues;
                    return data;
                  });
                }
              },
              upsertUserWalletItemValues(result, _args, cache) {
                // @ts-expect-error urql-internal-logic
                if (result?.upsertUserWalletItemValues?.values) {
                  cache.updateQuery({ query: getSelfWallet }, (data) => {
                    if (!data?.core.user.self?.walletItemValues) {
                      return null;
                    }
                    // @ts-expect-error urql-internal-logic
                    data.core.user.self.walletItemValues = result.upsertUserWalletItemValues.values;
                    return data;
                  });
                }
              },
              updateStudentRole(_result, _args, cache) {
                cache.invalidate('UserWalletItemValue');
              },
            },
            SchoolMutations: {
              approveStudentRoleRequest(_result, args, cache) {
                cache.invalidate('StudentRoleRequest');
                cache.invalidate('StudentRolePaginator');
                cache.invalidate('StudentRoleInvitePaginator');
              },
              declineStudentRoleRequest(_result, args, cache) {
                cache.invalidate('StudentRoleRequest');
              },
              inviteStudents(_result, args, cache) {
                cache.invalidate('StudentRoleInvitePaginator');
              },
              deleteStudentRoleInvite(_result, args, cache) {
                cache.invalidate('StudentRoleInvite');
              },
              bulkRespondStudentRoleRequests(_result: SchoolMutations, args: BulkRespondStudentRoleRequestsInput, cache) {
                if (_result.bulkRespondStudentRoleRequests.success === true) {
                  cache.invalidate('StudentRoleRequest');

                  if (args.status === RespondStudentRoleRequestStatus.Approved) {
                    cache.invalidate('StudentRolePaginator');
                  }
                }
              },
              bulkResendStudentRoleInvites(_result: SchoolMutations, args, cache) {
                if (_result.bulkResendStudentRoleInvites.success === true) {
                  cache.invalidate('StudentRoleInvite');
                }
              },
              bulkDeleteStudentRoleInvites(_result: SchoolMutations, args, cache) {
                if (_result.bulkDeleteStudentRoleInvites.success === true) {
                  cache.invalidate('StudentRoleInvite');
                }
              },
              bulkDeleteStudentRoles(_result: SchoolMutations, args, cache) {
                if (_result.bulkDeleteStudentRoles.success === true) {
                  cache.invalidate('StudentRolePaginator');
                }
              },
            },
            AuthMutations: {
              logout(_result, args, cache) {
                cache.invalidate('CoreQueries');
                cache.invalidate('CoreMutations');
              },
            },
            UserMutations: {
              addStudentRoleToTeacher(_result, args, cache) {
                cache.invalidate('StudentRolePaginator');
              },
            },
            Subscription: {
              studentRoleRequestCreated(_result, args, cache) {
                // clean paginator cache when event is being captured outside manage-student-invites
                // manage-student-invites has a new-data notification banner
                if (nuxtApp._route.name !== 'manage-student-invites') {
                  cache.invalidate('StudentRoleRequestPaginator');
                }
              },
              studentRoleInviteUpdated(_result, args, cache) {
                // handle for studentInvite accepted , warning the user if invite has been accepted / declined
                // and refresh the user table or refresh the user invite table
                const updated = _result?.studentRoleInviteUpdated;
                if (!updated) {
                  return;
                }

                if ((<SchoolStudentRoleInviteFieldsFragment>updated).status === RoleInviteStatus.Accepted) {
                  cache.invalidate('StudentRolePaginator');
                  cache.invalidate('StudentRoleRequestPaginator');
                }
              },
            },
          },
        }),
        _ssrExchange,
        mapExchange({
          onResult: (result) => {
            if (import.meta.server) {
              return result;
            }

            const { reset, refreshExpiry } = useAuthStore();

            // Streamed responses do not bump Laravel session cookie expiry, only the initial request, such as with a subscription, so we avoid bumping the expiry tracking on the FE
            if (!result.hasNext) {
              refreshExpiry();
              GQL_OP_STREAMING_SET.delete(result.operation.key);
            } else if (!GQL_OP_STREAMING_SET.has(result.operation.key)) {
              refreshExpiry();
              GQL_OP_STREAMING_SET.add(result.operation.key);
            }

            if (!result.error) {
              return result;
            }

            const unauthenticatedError = findGraphqlErrorWithCode(result.error, 'UNAUTHENTICATED');

            if (unauthenticatedError) {
              showError({ statusCode: 401, statusMessage: <string>unauthenticatedError.extensions.clientMessage });
              reset();
              return result;
            }

            const unauthorizedError = findGraphqlErrorWithCode(result.error, 'UNAUTHORIZED');
            if (unauthorizedError) {
              showError({ statusCode: 403, statusMessage: <string>unauthorizedError.extensions.clientMessage });
              return result;
            }

            return result;
          },
        }),
        fetchExchange,
        subscriptionExchange({ forwardSubscription: forwardSubscriptionToPusher }),
      ],
      fetchOptions: {
        headers: {
          ...(ssrHeaders && ssrHeaders),
          origin: siteDomain,
        },
      },
    });

    nuxtApp.vueApp.provide('$urql', ref(urqlClient));
    nuxtApp.provide('urql', urqlClient);

    return { provide: { urqlClient } };
  },
});

declare module '#app' {
  interface NuxtApp {
    $urqlClient: Client;
  }
}
