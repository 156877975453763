import type { CoachWidgetResponse } from '~/types/Activity/CoachWidgetResponse';
import type { AIChatMessageType } from '~/types/message';
import {
  aiChatResponseEventSubscription,
  aiPillsResponseEventSubscription,
  aiWidgetResponseEventSubscription,
  aiErrorResponseEventSubscription,
} from '~/graphql/documents/aiassistant';

export interface Pill {
  message: string;
  nextTopic: string;
}

export interface Message {
  type: AIChatMessageType;
  content: string;
  inputId?: string;
}

export const useAIChatStore = defineStore('chat', () => {
  const { $i18n, $urqlClient, $logErrorMessage } = useNuxtApp();
  const authStore = useAuthStore();

  const pills = ref<Pill[]>([]);
  const conversationHistory = ref<Message[]>([]);

  const conversationId = useCookie('assistant-conversation-id');

  const widgetResponse = ref<CoachWidgetResponse | null>(null);
  const chatInitialised = ref(false);
  const hasAiError = ref(false);
  const pillsResponseLoading = ref(false);
  const chatResponseLoading = ref(false);
  const widgetResponseLoading = ref(false);
  const currentInputId = ref<string | null>(null);

  const inputProcessingTimeout = ref<ReturnType<typeof setTimeout> | null>(null);

  const setAllResponsesAsLoading = () => {
    pillsResponseLoading.value = true;
    chatResponseLoading.value = true;
    widgetResponseLoading.value = true;
  };

  const anythingIsLoading = computed(() => {
    return pillsResponseLoading.value || chatResponseLoading.value || widgetResponseLoading.value;
  });

  const resetAiChatStore = () => {
    pills.value = [];
    conversationHistory.value = [];
    chatInitialised.value = false;
    pillsResponseLoading.value = false;
    chatResponseLoading.value = false;
    widgetResponseLoading.value = false;
    conversationId.value = ulid();
    widgetResponse.value = null;
    currentInputId.value = null;
  };

  const getLastMessage = () => {
    if (!conversationHistory.value.length) {
      return null;
    }

    return conversationHistory.value[conversationHistory.value.length - 1];
  };

  const initialiseChat = () => {
    if (chatInitialised.value || !authStore.user) {
      return;
    }

    if (!conversationId.value) {
      conversationId.value = ulid();
    }

    const allMessages = authStore.user.assistantConversationMessages;

    const lhsMessages = allMessages
      .filter((message) => message.uiType && ['user', 'ai', 'action', 'info'].includes(message.uiType))
      .map((message) => {
        return {
          type: <AIChatMessageType>message.uiType,
          content: message.content,
        };
      });

    conversationHistory.value = [{ type: 'ai', content: $i18n.t('general.coachIcebreaker') }, ...lhsMessages];

    const lastMessage = allMessages.length ? allMessages[allMessages.length - 1] : null;
    const lastPillsMessage = allMessages.findLast((message) => message.uiType === 'pills');

    if (lastPillsMessage && lastMessage && lastPillsMessage.inputId === lastMessage.inputId) {
      pills.value = lastPillsMessage ? JSON.parse(lastPillsMessage.content).pills : null;
    }

    const latestWidgetMessage = allMessages.findLast((message) => message.uiType === 'widget');
    widgetResponse.value = latestWidgetMessage ? JSON.parse(latestWidgetMessage.content) : null;

    if (lastMessage) {
      currentInputId.value = lastMessage.inputId;
    }

    chatInitialised.value = true;
  };

  const monitorInputProcessing = () => {
    if (import.meta.client) {
      inputProcessingTimeout.value = setTimeout(() => {
        $logErrorMessage('Process input timeout error');
        hasAiError.value = true;
      }, 60000);
    }
  };

  if (import.meta.client) {
    const unsubscribers = reactive({
      chat: <(() => void) | null>null,
      pills: <(() => void) | null>null,
      widget: <(() => void) | null>null,
      error: <(() => void) | null>null,
    });

    const unsubscribeAll = () => {
      if (unsubscribers.chat) {
        unsubscribers.chat();
        unsubscribers.chat = null;
      }
      if (unsubscribers.pills) {
        unsubscribers.pills();
        unsubscribers.pills = null;
      }
      if (unsubscribers.widget) {
        unsubscribers.widget();
        unsubscribers.widget = null;
      }
      if (unsubscribers.error) {
        unsubscribers.error();
        unsubscribers.error = null;
      }
    };

    watchEffect(() => {
      if (authStore.user && conversationId.value) {
        const { unsubscribe: chatUnSub } = $urqlClient
          .subscription(aiChatResponseEventSubscription, { userId: authStore.authId, conversationId: conversationId.value })
          .subscribe((result) => {
            if (!result.data?.aiChatResponseEvent) {
              return;
            }

            if (!result.data.aiChatResponseEvent.message.content) {
              hasAiError.value = true;
              $logErrorMessage('aiChatResponseEvent has no message content');
              return;
            }

            const { content } = result.data.aiChatResponseEvent.message;

            const lastMessage = getLastMessage();

            if (lastMessage?.type === 'ai' || lastMessage?.type === 'info') {
              lastMessage.content = content;
            }

            chatResponseLoading.value = false;
          });
        unsubscribers.chat = chatUnSub;

        const { unsubscribe: pillsUnSub } = $urqlClient
          .subscription(aiPillsResponseEventSubscription, { userId: authStore.authId, conversationId: conversationId.value })
          .subscribe((result) => {
            if (!result.data?.aiPillsResponseEvent) {
              return;
            }

            if (!result.data.aiPillsResponseEvent.message.content) {
              hasAiError.value = true;
              $logErrorMessage('aiPillsResponseEvent has no message content');
              return;
            }

            const { content } = result.data.aiPillsResponseEvent.message;

            if (content === 'no-pills-needed') {
              pillsResponseLoading.value = false;
              pills.value = [];
              return;
            }

            pills.value = JSON.parse(content).pills;
            pillsResponseLoading.value = false;
          });
        unsubscribers.pills = pillsUnSub;

        const { unsubscribe: widgetUnSub } = $urqlClient
          .subscription(aiWidgetResponseEventSubscription, { userId: authStore.authId, conversationId: conversationId.value })
          .subscribe((result) => {
            if (!result.data?.aiWidgetResponseEvent) {
              return;
            }

            if (!result.data.aiWidgetResponseEvent.message.content) {
              hasAiError.value = true;
              $logErrorMessage('aiWidgetResponseEvent has no message content');
              return;
            }

            const { content } = result.data.aiWidgetResponseEvent.message;

            if (content === 'no-new-widget') {
              widgetResponseLoading.value = false;
              return;
            }

            widgetResponse.value = JSON.parse(content);
            widgetResponseLoading.value = false;
          });
        unsubscribers.widget = widgetUnSub;

        const { unsubscribe: errorUnSub } = $urqlClient
          .subscription(aiErrorResponseEventSubscription, { userId: authStore.authId, conversationId: conversationId.value })
          .subscribe((result) => {
            if (!result.data?.aiErrorResponseEvent) {
              return;
            }

            const lastMessage = getLastMessage();

            $logErrorMessage(`AI Error Subscription triggered - ${result.data.aiErrorResponseEvent.message.content}`, {
              conversationId: conversationId.value,
              inputId: currentInputId.value,
              lastMessage,
            });

            hasAiError.value = true;
          });
        unsubscribers.error = errorUnSub;
      } else {
        unsubscribeAll();
      }
    });
  }

  if (import.meta.client) {
    watchEffect(() => {
      if (!anythingIsLoading.value && inputProcessingTimeout.value) {
        clearTimeout(inputProcessingTimeout.value);
      }
    });
  }

  return {
    pills,
    conversationHistory,
    conversationId,
    widgetResponse,
    hasAiError,
    chatResponseLoading,
    pillsResponseLoading,
    widgetResponseLoading,
    anythingIsLoading,
    currentInputId,
    initialiseChat,
    resetAiChatStore,
    setAllResponsesAsLoading,
    monitorInputProcessing,
  };
});
