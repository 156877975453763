import UserActivityError from '~/utils/errors/UserActivityError';
import UnexpectedGraphqlError from '~/utils/errors/UnexpectedGraphqlError';
import { H3Error } from 'h3';

export const useRequestHost = () => {
  return import.meta.server ? useRequestHeaders().host : window.location.host;
};

export const useDomainConfig = () => {
  const config = useRuntimeConfig();
  const siteDomain = useRequestHost().replace(/^www\./, '');

  const isManageDomain = siteDomain.startsWith('schools.');

  const clusterDomain = siteDomain.replace(/^schools\./, '');

  const useHttpForApi = import.meta.server && config.public.useHttpForApiOnServer;
  const useHttp = useHttpForApi || config.public.useHttp;

  return {
    siteDomain,
    clusterDomain,
    apiUrl: `${useHttp ? 'http' : 'https'}://${siteDomain}/api`,
    isManageDomain,
  };
};

export const useUrqlClient = () => {
  return useNuxtApp().$urqlClient;
};

export const useClassYears = () => {
  const configStore = useConfigStore();
  const country = configStore.publicConfig?.country;
  if (!country) {
    throw new Error('configStore.publicConfig.country is not defined');
  }

  const { t } = useI18n();
  const yearGroupLabel = computed(() => t('general.classYear'));
  const currentClassYearOptions = computed(
    () =>
      country.currentClassYearOptions.map(({ grade, year }) => ({
        label: `${yearGroupLabel.value} ${grade}`,
        value: year,
      })) || [],
  );

  const classYearToGradeMapping = computed(() => {
    const map: Map<number, number> = new Map();
    country.currentClassYearOptions.forEach(({ grade, year }) => {
      map.set(year, grade);
    });
    return map;
  });

  const getYearGroupFromClassYearText = (classYear?: number | null) => {
    if (!classYear) {
      return t('general.graduated');
    }

    return classYearToGradeMapping.value.get(classYear);
  };

  const allClassYearOptions = computed(
    () =>
      country.allClassYearOptions.map(({ grade, year }) => ({
        label: `${yearGroupLabel.value} ${grade}`,
        value: year,
      })) || [],
  );

  const classYearNotUndefinedRule = useValidation().notUndefinedRule(
    t('general.errors.optionShouldBeSelected', { param: t('general.formFields.grade.label') }),
  );

  const gradeClassYearMapping = computed(() => {
    const map: Map<string, number | null> = new Map();
    map.set('graduated', null);

    country.currentClassYearOptions.forEach(({ year, grade }) => {
      map.set(grade.toString(), year);
    });

    return map;
  });

  return {
    getYearGroupFromClassYearText,
    classYearToGradeMapping,
    currentClassYearOptions,
    allClassYearOptions,
    yearGroupLabel,
    classYearNotUndefinedRule,
    gradeClassYearMapping,
  };
};

export const setupErrorHandling = (errorHandler: { logError: (error: Error, additionalContext?: object) => void }) => {
  const { siteDomain } = useDomainConfig();
  const route = useRoute();

  const ssrHeaders = useRequestHeaders(['user-agent']);

  const globalContext = computed(() => {
    return {
      ...(ssrHeaders && ssrHeaders),
      siteDomain,
      path: route.fullPath,
    };
  });

  const logErrorMessage = (message: string, additionalContext: object = {}) => {
    const loggedError = new Error(message);
    loggedError.name = 'LoggedError';
    errorHandler.logError(loggedError, { ...additionalContext, ...globalContext.value });
  };

  const logError = (error: Error, additionalContext: object = {}) => {
    errorHandler.logError(error, { ...additionalContext, ...globalContext.value });
  };

  const nuxtApp = useNuxtApp();

  const handleGlobalHookError = (error: unknown, hook: string) => {
    if (!(error instanceof Error)) {
      const notErrorError = new Error('global hook error was not an Error object');
      errorHandler.logError(notErrorError, { hook, ...globalContext.value });
      return;
    }

    if (error instanceof H3Error) {
      errorHandler.logError(error, { hook, ...globalContext.value, data: error.data });
      return;
    }

    if (error instanceof UserActivityError) {
      errorHandler.logError(error, { hook, ...globalContext.value, userActivityId: error.userActivityId });
      return;
    }

    if (error instanceof UnexpectedGraphqlError) {
      errorHandler.logError(error, { hook, ...globalContext.value, graphqlError: error.graphqlError?.toString() });
      return;
    }

    errorHandler.logError(error, { hook, ...globalContext.value });
  };

  // Global error handling
  nuxtApp.hook('vue:error', (error) => {
    handleGlobalHookError(error, 'vue:error');
  });

  nuxtApp.hook('app:error', (error) => {
    handleGlobalHookError(error, 'app:error');
  });

  return { logErrorMessage, logError };
};

export const useAnalyticsService = () => {
  return useNuxtApp().$analytics;
};

export const useOrdinalNumber = () => {
  const { t } = useI18n();

  const configStore = useConfigStore();
  const country = configStore.publicConfig?.country;
  if (!country) {
    throw new Error('configStore.publicConfig.country is not defined');
  }

  const pluralMapping = (termKey: Intl.LDMLPluralRule) => {
    switch (termKey) {
      case 'one':
        return t('general.numericalOrdinal.one');
      case 'two':
        return t('general.numericalOrdinal.two');
      case 'few':
        return t('general.numericalOrdinal.three');
      case 'zero':
        return;
      default:
        return t('general.numericalOrdinal.other');
    }
  };

  const plurals = new Intl.PluralRules(country.defaultLocale, { type: 'ordinal' });
  const getOrderNumber = (value: number) => `${value}${pluralMapping(plurals.select(value))}`;

  return { getOrderNumber };
};

export const useCrossSitesConfig = () => {
  const { clusterDomain } = useDomainConfig();
  const isManageReferred = useCookie(`isManageReferred-${clusterDomain}`, { sameSite: 'none', domain: `.${clusterDomain}`, path: '/', secure: true });

  const setManageReferred = () => {
    isManageReferred.value = Date.now().toString();
  };

  const clear = () => {
    isManageReferred.value = undefined;
  };

  return { isManageReferred, setManageReferred, clear };
};
