<script lang="ts" setup>
import type { NavItem } from '~ui/types/navigation';
import { onClickOutside } from '@vueuse/core';

interface Props {
  open: boolean;
  navItems: NavItem[];
  footerItems?: NavItem[];
}

const props = withDefaults(defineProps<Props>(), {
  footerItems: () => [],
});

const emit = defineEmits<{
  close: [];
}>();

const sidebarRef = ref<HTMLElement | null>(null);

onClickOutside(sidebarRef, () => {
  if (props.open) {
    emit('close');
  }
});

const transitionNavClasses = computed(() => {
  return twMerge(
    cva('transition-transform duration-200 ease-in-out', {
      variants: {
        open: {
          true: 'translate-x-0',
          false: '-translate-x-full',
        },
      },
    })({
      open: props.open,
    }),
  );
});
</script>

<template>
  <aside
    id="sidebar"
    ref="sidebarRef"
    class="fixed left-0 top-0 z-40 h-dvh w-64"
    aria-label="sidebar"
    :class="transitionNavClasses"
    data-testid="sidebar-aside"
    @keydown.esc="emit('close')"
  >
    <div
      class="h-full overflow-y-auto bg-white py-4 shadow-[0px_1.6px_3.2px_0px_rgba(0,0,0,0.04)_inset,12.8px_57.6px_83.2px_0px_rgba(2,0,98,0.24),-1.6px_-6.4px_6.4px_0px_rgba(0,0,0,0.06)_inset,0px_2px_3.4px_0px_rgba(255,255,255,0.60)_inset]"
    >
      <button
        aria-label="close sidebar"
        data-testid="sidebar-close"
        aria-expanded="true"
        class="mb-10 ml-3 flex size-[32px] items-center justify-center rounded-full border border-grey-100 bg-white"
        @click="emit('close')"
      >
        <UIAssetIcon path="cross" size="sm" />
      </button>
      <ul class="text-base text-primary-700">
        <UINavigationSideLink
          v-for="(link, i) in navItems.filter((item) => !item.hide)"
          :key="`link-base-${i}`"
          :label="link.label"
          :iconPath="link.iconPath"
          :to="link.to"
          :isOpen="true"
          :activeRoutes="link.activeRoutes"
          @close="emit('close')"
        />
      </ul>
      <div class="absolute bottom-0 left-0 w-full">
        <slot name="customFooterComponents" />
        <ul v-if="footerItems.length" class="w-full text-base text-primary-700">
          <UINavigationSideLink
            v-for="(link, i) in footerItems.filter((item) => !item.hide)"
            :key="`link-footer-${i}`"
            :label="link.label"
            :iconPath="link.iconPath"
            :to="link.to"
            :isOpen="true"
            :activeRoutes="link.activeRoutes"
            :onClick="link.onClick"
            @close="emit('close')"
          />
        </ul>
    </div>
    </div>
  </aside>
</template>
