export const useUiStore = defineStore('uiStore', () => {
  const isNavOpen = ref<boolean>(false);
  const isActivitiesBarOpen = ref<boolean>(true);
  const dashboardConfettiCompleted = ref<boolean>(false);
  const justLoggedIn = ref<boolean>(false);
  const isActivityBackNavigation = ref<boolean>(false);
  const isMobileRhsOpen = ref<boolean>(false);

  const resetUiStore = () => {
    isNavOpen.value = false;
    isActivitiesBarOpen.value = true;
    dashboardConfettiCompleted.value = false;
    justLoggedIn.value = false;
    isActivityBackNavigation.value = false;
    isMobileRhsOpen.value = false;
  };

  return { isNavOpen, isActivitiesBarOpen, dashboardConfettiCompleted, justLoggedIn, isActivityBackNavigation, isMobileRhsOpen, resetUiStore };
});
