<script setup lang="ts">
import { ref } from 'vue';
import type { NavItem } from '~ui/types/navigation';
interface Props {
  navItems: NavItem[];
  footerItems?: NavItem[];
}

withDefaults(defineProps<Props>(), {
  footerItems: () => [],
});

const isSidebarOpen = ref<boolean>(false);

const toggleNav = (): void => {
  isSidebarOpen.value = !isSidebarOpen.value;
};
</script>

<template>
  <header>
    <div class="container relative p-4">
      <div class="flex items-center justify-between">
        <button class="mt-5 h-full" :aria-label="isSidebarOpen ? 'close navigation' : 'open navigation'" @click="toggleNav">
          <UIAssetIcon :path="isSidebarOpen ? 'cross' : 'hamburger'" size="md" class="flex" />
        </button>
      </div>
    </div>
    <div v-if="isSidebarOpen" class="absolute left-0 top-0 z-20 size-full bg-primary-500 text-white">
      <div class="px-7 py-4">
        <button class="mt-5 h-full" :aria-label="isSidebarOpen ? 'close navigation' : 'open navigation'" @click="toggleNav">
          <UIAssetIcon :path="isSidebarOpen ? 'cross' : 'hamburger'" size="sm" class="ml-1 flex" />
        </button>
      </div>
      <ul class="mt-6">
        <UINavigationSideLink
          v-for="(link, i) in navItems.filter((item) => !item.hide)"
          :key="`link-${i}`"
          :label="link.label"
          :iconPath="link.iconPath"
          :to="link.to"
          :isOpen="true"
          :activeRoutes="link.activeRoutes"
          :onClick="link.onClick"
          :badgeCount="link.badgeCount"
        />
      </ul>
      <ul v-if="footerItems.length" class="absolute bottom-0 left-0 w-full text-white">
        <UINavigationSideLink
          v-for="(link, i) in footerItems.filter((item) => !item.hide)"
          :key="`link-${i}`"
          :label="link.label"
          :iconPath="link.iconPath"
          :to="link.to"
          :isOpen="isSidebarOpen"
          :activeRoutes="link.activeRoutes"
          :onClick="link.onClick"
        />
      </ul>
    </div>
  </header>
</template>
