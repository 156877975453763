import { graphql } from '~/graphql/generated';

export const PROCESS_INPUT = graphql(`
  mutation processInput($input: AIAssistantInput!) {
    assistantAI {
      processInput(input: $input) {
        success
      }
    }
  }
`);

export const SAVE_FEEDBACK = graphql(`
  mutation saveFeedback($input: SaveAIAssistantFeedbackInput!) {
    assistantAI {
      saveFeedback(input: $input) {
        id
        success
      }
    }
  }
`);

export const aiChatResponseEventSubscription = graphql(`
  subscription aiChatResponseEvent($userId: ID!, $conversationId: ID!) {
    aiChatResponseEvent(userId: $userId, conversationId: $conversationId) {
      message {
        role
        content
      }
    }
  }
`);

export const aiPillsResponseEventSubscription = graphql(`
  subscription aiPillsResponseEvent($userId: ID!, $conversationId: ID!) {
    aiPillsResponseEvent(userId: $userId, conversationId: $conversationId) {
      message {
        role
        content
      }
    }
  }
`);

export const aiWidgetResponseEventSubscription = graphql(`
  subscription aiWidgetResponseEvent($userId: ID!, $conversationId: ID!) {
    aiWidgetResponseEvent(userId: $userId, conversationId: $conversationId) {
      message {
        role
        content
      }
    }
  }
`);

export const aiErrorResponseEventSubscription = graphql(`
  subscription aiErrorResponseEvent($userId: ID!, $conversationId: ID!) {
    aiErrorResponseEvent(userId: $userId, conversationId: $conversationId) {
      message {
        role
        content
      }
    }
  }
`);
