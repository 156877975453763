<script lang="ts" setup>
interface Props {
  to?: string | null;
  isOpen?: boolean;
  iconPath: string;
  label: string;
  activeRoutes: string[];
  onClick?: () => void | Promise<void>;
  badgeCount?: number;
  type?: 'default' | 'thin';
}

const props = withDefaults(defineProps<Props>(), {
  to: null,
  onClick: undefined,
  badgeCount: undefined,
  isOpen: false,
  type: 'default',
});
const emit = defineEmits<{
  close: [];
}>();
const route = useRoute();
const { toDataTestId } = useToDataTestId();

const isActive = computed<boolean>(() => {
  const { name } = route;

  if (typeof name === 'string') {
    return props.activeRoutes.includes(name);
  }

  return false;
});

const component = computed<string | object>(() => {
  if (props.to) {
    return defineNuxtLink({});
  }

  return 'button';
});

const classes = computed<string>(() => {
  return twMerge(
    cva('flex w-full items-center px-5 transition-all duration-300', {
      variants: {
        isOpen: {
          true: 'h-[72px] translate-x-0 transform gap-2',
          false: 'h-[72px] translate-x-1 transform',
        },
      },
    })({
      isOpen: props.isOpen,
    }),
  );
});

const activeClass = computed<string>(() => {
  return twMerge(
    cva('', {
      variants: {
        isActive: {
          true: 'bg-primary-600 text-white',
          false: 'hover:bg-primary-800',
        },
      },
    })({
      isActive: isActive.value,
    }),
  );
});

const iconClasses = computed<string>(() =>
  twMerge(
    cva('shrink-0 text-primary-700', {
      variants: {
        isActive: {
          true: 'fill-primary-700 text-white',
          false: 'fill-primary-700',
        },
        type: {
          thin: '',
          default: '',
        },
      },
      compoundVariants: [
        {
          type: 'thin',
          isActive: true,
          class: 'fill-white text-primary-500',
        },
        {
          type: 'thin',
          isActive: false,
          class: 'fill-primary-500 text-white',
        },
      ],
    })({
      isActive: isActive.value,
      type: props.type,
    }),
  ),
);
const clickHandler = async () => {
  if (component.value === 'button' && props.onClick) {
    await props.onClick();
  }

  emit('close');
};

const isLabelVisible = ref<boolean>(false);

watch(
  () => props.isOpen,
  (val) => {
    if (val) {
      setTimeout(() => {
        isLabelVisible.value = true;
      }, 150);
    } else {
      isLabelVisible.value = false;
    }
  },
);

const labelClasses = computed<string>(() => {
  return twMerge(
    cva('transition-all delay-700', {
      variants: {
        isLabelVisible: {
          true: '',
          false: '',
        },
        type: {
          thin: '',
          default: '',
        },
      },
      compoundVariants: [
        {
          type: 'thin',
          isLabelVisible: true,
          class: 'opacity-100',
        },
        {
          type: 'thin',
          isLabelVisible: false,
          class: 'opacity-0',
        },
      ],
    })({
      isLabelVisible: isLabelVisible.value,
      type: props.type,
    }),
  );
});

onMounted(() => {
  isLabelVisible.value = props.isOpen;
});
</script>

<template>
  <li :class="activeClass">
    <component :is="component" :aria-label="label" class="" :class="classes" :to="to" @click="clickHandler">
      <div class="relative flex">
        <UIAssetIcon :path="iconPath" size="md" :class="iconClasses" />
        <div
          v-if="badgeCount"
          class="absolute -right-1/3 -top-1/3 flex size-[20px] items-center justify-center rounded-full bg-red-500 p-1"
          :data-testid="`badge-count-${toDataTestId(label)}`"
        >
          <span class="text-[9.5px]">{{ badgeCount }}</span>
        </div>
      </div>
      <p v-show="isOpen" :class="labelClasses">{{ label }}</p>
    </component>
  </li>
</template>
