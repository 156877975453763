import routerOptions0 from "/var/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_eslint_152e84da572378267d0107b5d14531e0/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/var/www/apps/phoenix/nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}