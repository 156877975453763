import { graphql } from '~/graphql/generated/gql';

export const walletItemFragment = graphql(`
  fragment walletItemFields on WalletItem {
    id
    key
    type
    config {
      options {
        label
        value
      }
      subType
      width
      derived
      validation
      repeatable
      readOnly
      showIfKey
      showIfValue
      allowedFileTypeCategory
      uiType
    }
    parentId
    order
    children {
      id
      key
      type
      config {
        options {
          label
          value
        }
        subType
        width
        derived
        validation
        repeatable
        readOnly
        showIfKey
        showIfValue
        allowedFileTypeCategory
        uiType
      }
      order
      parentId
    }
  }
`);

export const userWalletContextFragment = graphql(`
  fragment userWalletContextFields on WalletContext {
    id
    key
    type
    relatedType
    relatedTypeId
    walletItems {
      id
      key
      pivot {
        walletContextUICategoryId
        order
      }
    }
    uiCategories {
      id
      key
      order
      parentId
    }
  }
`);

export const walletItemValueFragment = graphql(`
  fragment walletItemValueFields on UserWalletItemValue {
    id
    order
    data
    walletItemId
  }
`);

export const getSelfWallet = graphql(`
  query getSelfWallet {
    core {
      user {
        self {
          id
          walletItemValues {
            ...walletItemValueFields
          }
          userWalletContexts {
            ...userWalletContextFields
          }
        }
      }
    }
  }
`);

export const upsertUserWalletItemValues = graphql(`
  mutation upsertUserWalletItemValues($input: UpsertUserWalletItemValuesInput!) {
    upsertUserWalletItemValues(input: $input) {
      values {
        ...walletItemValueFields
      }
      user {
        id
        studentRoles {
          id
          firstName
          lastName
          stateId
        }
      }
    }
  }
`);

export const addUserWalletContexts = graphql(`
  mutation addUserWalletContexts($input: UserWalletContextInput!) {
    addUserWalletContexts(input: $input) {
      ...userWalletContextFields
    }
  }
`);

export const removeUserWalletContexts = graphql(`
  mutation removeUserWalletContexts($input: UserWalletContextInput!) {
    removeUserWalletContexts(input: $input) {
      ...userWalletContextFields
    }
  }
`);

export const getWalletItems = graphql(`
  query getWalletItems {
    walletItems {
      ...walletItemFields
    }
  }
`);

export const getStudentWallet = graphql(`
  query getStudentWallet($schoolId: ID!, $filters: StudentRoleFilters) {
    core {
      org {
        school {
          school(id: $schoolId) {
            id
            studentRoles(filters: $filters) {
              data {
                id
                user {
                  id
                  userWalletContexts {
                    ...userWalletContextFields
                  }
                  walletItemValues {
                    ...walletItemValueFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
