<script lang="ts" setup>
import { onClickOutside } from "@vueuse/core";
import type { ModalCloseMethod } from "~ui/types/modal";

interface Props {
  size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";
  customClasses?: string;
  hideCloseButton?: boolean;
  stopOutsideClose?: boolean;
  largerSidePadding?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: "lg",
  customClasses: "",
  hideCloseButton: false,
  stopOutsideClose: false,
  largerSidePadding: false,
});

const emit = defineEmits<{
  close: [howClosedModal: ModalCloseMethod];
}>();

const { elementRef: modalContent } = useFocusTrap();

onClickOutside(modalContent, () => {
  if (!props.stopOutsideClose) {
    emit("close", "outside");
  }
});

const variantProps = computed(() => {
  return twMerge(
    cva(
      "w-full overflow-y-auto overflow-y-hidden rounded-2xl bg-white",
      {
        variants: {
          size: {
            sm: "max-w-sm",
            md: "max-w-md",
            lg: "max-w-lg",
            xl: "max-w-xl",
            "2xl": "max-w-2xl",
            "3xl": "max-w-3xl",
            "4xl": "max-w-4xl",
          },
        },
      }
    )({
      size: props.size,
    })
  );
});

const modalContentContainer = computed(() =>  twMerge(
    cva(
      "overflow-x-hidden p-6 max-h-[calc(100vh-4em)] shadow-xs",
      {
        variants: {
          largerSidePadding: {
            true: "px-6 md:px-10",
            false: "",
          },
        },
      }
    )({
      largerSidePadding: props.largerSidePadding,
    })
  ));

const contentClasses = computed(() => {
  return twMerge(cva("w-full")({}), props.customClasses);
});
</script>

<template>
  <div
    aria-modal="true"
    class="fixed top-0 left-0 z-50 overflow-hidden bg-black bg-opacity-70 size-full flex items-center justify-center px-4"
    role="dialog"
  >
    <div
      ref="modalContent"
      :class="variantProps"
      class="p-0"
      @keydown.esc="emit('close', 'x')"
    >
      <!-- the close button section -->
      <div v-if="!hideCloseButton" class="relative w-full">
        <UIButton
          size="sm"
          :hideText="true"
          class="absolute right-4 top-4"
          iconPath="cross"
          variant="no-style"
          @click="emit('close', 'x')"
          @keydown.esc="emit('close', 'x')"
        />
      </div>
      <!-- the modal content section (header + body) -->
      <div :class="modalContentContainer">
        <div class="flex items-start justify-between w-full text-left">
          <div>
            <slot name="heading" />
          </div>
        </div>
        <div :class="contentClasses" class="relative">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
