import { graphql } from '~/graphql/generated/gql';

export const systemConfigFragment = graphql(`
  fragment systemConfigFields on SystemConfig {
    isSkippable
    repeatMode
    autoload {
      isAutoload
      servingUrlPath
    }
  }
`);

export const activityInitialValueUnionFragment = graphql(`
  fragment activityInitialValueUnionFields on ActivityInitialValueUnion {
    ... on OccupationSearchInitialValue {
      __typename
      OccupationSearchInitialValue {
        itemsAddedToVisionBoard
      }
    }

    ... on InterestSelectionInitialValue {
      __typename
    }

    ... on JobGridInitialValue {
      __typename
    }

    ... on JobOverviewInitialValue {
      __typename
    }

    ... on JobInfoInitialValue {
      __typename
    }

    ... on JobInfoInitialValue {
      __typename
    }

    ... on InstitutionSearchInitialValue {
      __typename
      InstitutionSearchInitialValue {
        itemsAddedToVisionBoard
      }
    }

    ... on InstitutionGridInitialValue {
      __typename
    }

    ... on InstitutionOverviewInitialValue {
      __typename
    }

    ... on InstitutionAcceptanceCriteriaInitialValue {
      __typename
    }

    ... on EducationPathwaysInitialValue {
      __typename
      EducationPathwaysInitialValue {
        itemsAddedToVisionBoard
      }
    }

    ... on CareerVisionBoardInitialValue {
      __typename
      CareerVisionBoardInitialValue {
        answer
      }
    }

    ... on FillCareerProfileInitialValue {
      __typename
      FillCareerProfileInitialValue {
        answer
      }
    }

    ... on CareerVisionBoardReviewInitialValue {
      __typename
      CareerVisionBoardReviewInitialValue {
        answer
      }
    }

    ... on AcademicPlanInitialValue {
      __typename
      AcademicPlanInitialValue {
        answer
      }
    }

    ... on InterestSelectionInitialValue {
      __typename
      InterestSelectionInitialValue {
        selectedInterests
      }
    }

    ... on PreplanAIInitialValue {
      __typename
      PreplanAIInitialValue {
        summary
        conversation
        persona
        questions {
          number
          question
          answer
          options {
            id
            value
            label
          }
        }
      }
    }

    ... on PreplanSummaryInitialValue {
      __typename
      PreplanSummaryInitialValue {
        answer
      }
    }
  }
`);

export const activityConfigUnionFragment = graphql(`
  fragment activityConfigUnionFields on ActivityConfigUnion {
    ... on InterestSelectionConfig {
      __typename
      InterestSelectionConfig {
        prompt
        options {
          label
          value
          image
        }
      }
    }

    ... on PreplanSummaryConfig {
      __typename
      PreplanSummaryConfig {
        prompt
        summary {
          summary
          sentenceSummary
        }
        traits {
          title
          description
          icon
        }
        potentialJobs {
          industry_name
          careers {
            why_suitable
            career_name
          }
        }
        selectedInterests
        raisecProfile {
          acronym
          explanation
        }
      }
    }

    ... on PreplanAIConfig {
      __typename
      PreplanAIConfig {
        interests
      }
    }

    ... on OccupationSearchConfig {
      __typename
      OccupationSearchConfig {
        heading
        assistantPrompt
        fieldOfInterest {
          industry_id
          industry_name
        }
        onetSkillName
        interest
        jobsSaved {
          id
          name
        }
      }
    }

    ... on JobGridConfig {
      __typename
      JobGridConfig {
        data
      }
    }

    ... on JobOverviewConfig {
      __typename
      JobOverviewConfig {
        data
      }
    }

    ... on JobInfoConfig {
      __typename
      JobInfoConfig {
        data
      }
    }

    ... on InstitutionInfoConfig {
      __typename
      InstitutionInfoConfig {
        data
      }
    }

    ... on InstitutionOverviewConfig {
      InstitutionOverviewConfig {
        data
      }
    }

    ... on InstitutionGridConfig {
      InstitutionGridConfig {
        data
      }
    }

    ... on IntermittentPathwaysConfig {
      __typename
      IntermittentPathwaysConfig {
        data
      }
    }

    ... on InstitutionSearchConfig {
      __typename
      InstitutionSearchConfig {
        heading
        fieldOfInterest {
          industry_id
          industry_name
        }
        addressStateName
        addressStateCode
        institutionsSaved {
          id
          name
        }
      }
    }

    ... on AcademicPlanConfig {
      __typename
      AcademicPlanConfig {
        heading
        assistantPrompt
        buttonTxt
        data {
          id
          name
          academic_plan {
            grade
            subjects {
              id
              course
              reasons
            }
            electives {
              id
              course
              reasons
            }
          }
        }
      }
    }

    ... on EducationPathwaysConfig {
      __typename
      EducationPathwaysConfig {
        heading
        assistantPrompt
        buttonTxt
        data {
          id
          name
          pathways {
            id
            name
            description
          }
        }
        fieldOfInterest {
          industry_id
          industry_name
        }
        pathwayTypePreference {
          id
          name
        }
      }
    }
  }
`);
