<script lang="ts" setup>
import type { NavItem } from '@year13/ui';
import { useSubscription } from '@urql/vue';
import { studentRoleRequestCreatedSubscription } from '~/graphql/documents/core';
import { Permission } from '~/graphql/generated/graphql';

const { t } = useI18n();
const { logout, currentSchoolId, hasPermissions, canManageStudents } = useAuth();
const { schoolRequestsCount } = useSchoolInviteCount();

const route = useRoute();

const invitePaths = ['manage-student-invites', 'manage-student-invites-invite'];
const navItems: Ref<NavItem[]> = computed(() => [
  {
    to: '/dashboard',
    label: t('general.nav.students'),
    iconPath: 'dashboard',
    activeRoutes: ['manage-dashboard'],
    hide: !hasPermissions([Permission.ViewSchool, Permission.ViewStudents]),
  },
  {
    to: '/student-invites',
    label: t('general.nav.manage.studentInvites'),
    iconPath: invitePaths.includes(route.name?.toString() || '') ? 'user-circle-solid' : 'user-circle',
    activeRoutes: invitePaths,
    badgeCount: schoolRequestsCount.value,
    hide: !canManageStudents.value,
  },
]);

const footerItems = [
  {
    to: '/account',
    label: t('general.nav.profile'),
    iconPath: 'cog',
    activeRoutes: ['manage-account'],
  },
  {
    label: t('general.nav.logout'),
    iconPath: 'logout',
    activeRoutes: [],
    onClick: logout,
  },
];

useSubscription({
  query: studentRoleRequestCreatedSubscription,
  variables: computed(() => ({
    schoolId: currentSchoolId.value,
  })),
  pause: computed(() => !currentSchoolId.value || !canManageStudents.value),
});
</script>

<template>
  <LayoutPrivate :isManage="true" :navItems="navItems" :footerItems="footerItems">
    <template #default>
      <slot />
    </template>
    <template #top-content>
      <header class="mb-8 flex w-full items-center justify-between">
        <CoreSchoolSelect />
        <slot name="page-action" />
      </header>
    </template>
  </LayoutPrivate>
</template>
