<script lang="ts" setup>
interface Props {
  path: string;
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
}
const props = withDefaults(defineProps<Props>(), {
  size: 'md',
});

const iconSizes = {
  '2xs': 'size-2',
  xs: 'size-3',
  sm: 'size-4',
  md: 'size-6',
  lg: 'size-7',
  xl: 'size-10',
  '2xl': 'size-12',
  '3xl': 'size-24',
};

const assetPath = computed(() => {
  return `icons/${props.path}.svg`;
});
</script>

<template>
  <UIAsset :path="assetPath" :class="[iconSizes[size]]" />
</template>
