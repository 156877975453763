import { coreSiteConfig, privateSiteConfigFragment } from '~/graphql/documents/core';
import UnexpectedGraphqlError from '~/utils/errors/UnexpectedGraphqlError';
import NodeCache from 'node-cache';
import buildInfo from '@/buildInfo';
import type { AcceptedFileTypes, CoreSiteConfigQuery, PrivateSiteConfigFieldsFragment } from '~/graphql/generated/graphql';
import { useFragment, type FragmentType } from '~/graphql/generated';
import { defineStore } from 'pinia';

const configCache = new NodeCache();

export const useConfigStore = defineStore('config', () => {
  const { clusterDomain } = useDomainConfig();
  const clusterConfigCacheKey = `${clusterDomain}-config-res`;
  const config = useRuntimeConfig();

  const cluster = ref<null | CoreSiteConfigQuery['core']['org']['cluster']>(null);
  const publicConfig = ref<null | CoreSiteConfigQuery['core']['site']['publicSiteConfig']>(null);
  const i18nMessages = ref<null | object>(null);
  const acceptedFileTypes = ref<null | AcceptedFileTypes>(null);

  const _privateSiteConfig = ref<PrivateSiteConfigFieldsFragment | null>(null);

  const { $urqlClient } = useNuxtApp();

  const loadConfig = async () => {
    const cachedConfigData = <CoreSiteConfigQuery>configCache.get(clusterConfigCacheKey);

    if (config.public.ssrConfigCacheEnabled && cachedConfigData) {
      cluster.value = cachedConfigData.core.org.cluster;
      publicConfig.value = cachedConfigData.core.site.publicSiteConfig;
      i18nMessages.value = <object>JSON.parse(cachedConfigData.core.site.i18nMessages);
      acceptedFileTypes.value = cachedConfigData.core.site.acceptedFileTypes;
      return;
    }

    const res = await $urqlClient.query(coreSiteConfig, { domain: clusterDomain });

    if (!res?.data?.core.org.cluster) {
      throw new UnexpectedGraphqlError('Could not load cluster config', res.error);
    }

    if (!res.data.core.site.publicSiteConfig) {
      throw new UnexpectedGraphqlError('Could not load public site config', res.error);
    }

    if (!res.data.core.site.i18nMessages) {
      throw new UnexpectedGraphqlError('Could not load i18n messages', res.error);
    }

    if (!res.data.core.site.acceptedFileTypes) {
      throw new UnexpectedGraphqlError('Could not load accepted file types', res.error);
    }

    if (!res.extensions?.build) {
      throw new UnexpectedGraphqlError('Build missing from extensions', res.error);
    }

    // As an old version of app can still be in load balancer while a deploy is
    // going out, we need to ensure there is a build match before caching on a
    // nuxt container to avoid issues
    if (config.public.ssrConfigCacheEnabled && res.extensions?.build === buildInfo.build) {
      configCache.set(clusterConfigCacheKey, res.data, 3600);
    }

    cluster.value = res.data.core.org.cluster;
    publicConfig.value = res.data.core.site.publicSiteConfig;
    i18nMessages.value = JSON.parse(res.data.core.site.i18nMessages);
    acceptedFileTypes.value = res.data.core.site.acceptedFileTypes;
  };

  const setPrivateSiteConfig = (fragment: FragmentType<typeof privateSiteConfigFragment>) => {
    _privateSiteConfig.value = useFragment(privateSiteConfigFragment, fragment);
  };

  const privateSiteConfig = computed(() => _privateSiteConfig.value?.privateSiteConfig);

  return {
    cluster,
    publicConfig,
    i18nMessages,
    acceptedFileTypes,
    privateSiteConfig,
    loadConfig,
    setPrivateSiteConfig,
  };
});
