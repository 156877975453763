<script lang="ts" setup>
import type { NavItem } from '~ui/types/navigation';
import LayoutPrivate from './LayoutPrivate.vue';

interface Props {
  hasMargins?: boolean;
}

withDefaults(defineProps<Props>(), {
  hasMargins: true,
});

const { t } = useI18n();
const { logout } = useAuth();

const navItems = computed((): NavItem[] => {
  return [
    {
      to: '/dashboard',
      label: t('general.platform.dashboardTitle'),
      iconPath: 'phoenix/ai-avatar',
      activeRoutes: ['plan-dashboard'],
    },
    {
      to: '/portfolio',
      label: 'Portfolio',
      iconPath: 'id-card',
      activeRoutes: ['plan-portfolio'],
    },
    {
      to: '/my-results',
      label: 'My Results',
      iconPath: 'award',
      activeRoutes: ['plan-my-results'],
    },
  ];
});

const footerItems: NavItem[] = [
  {
    to: '/account',
    label: t('general.nav.profile'),
    iconPath: 'user-circle',
    activeRoutes: ['plan-account'],
  },
  {
    label: t('general.nav.logout'),
    iconPath: 'logout',
    activeRoutes: [],
    onClick: logout,
  },
];
</script>

<template>
  <LayoutPrivate :footerItems="footerItems" :hasMargins="hasMargins" :navItems="navItems">
    <template #default>
      <slot />
    </template>
    <template #extras>
      <slot name="extras" />
    </template>
  </LayoutPrivate>
</template>
