import payload_plugin_FEtdNsd8ik from "/var/www/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.5.4_vue@3.5.13_typescript@5.5.4__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_BsulduVHvU from "/var/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_eslint_152e84da572378267d0107b5d14531e0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vTeYUOQtmo from "/var/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_eslint_152e84da572378267d0107b5d14531e0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Fxw36GxM2U from "/var/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_eslint_152e84da572378267d0107b5d14531e0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_kUebwhLlgt from "/var/www/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import _03_seo_fZau0DgIwP from "/var/www/apps/phoenix/nuxt/plugins/03.seo.ts";
import payload_client_MagVgg7EQV from "/var/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_eslint_152e84da572378267d0107b5d14531e0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_WQmv81hSn8 from "/var/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_eslint_152e84da572378267d0107b5d14531e0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XSrcKMp7aU from "/var/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_eslint_152e84da572378267d0107b5d14531e0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Vc2AwskmKx from "/var/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_eslint_152e84da572378267d0107b5d14531e0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Czj6bdTzh0 from "/var/www/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.5.4_vue@3.5.13_typescript@5.5.4__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/apps/phoenix/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_hhhXyzjlVu from "/var/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_encoding@0.1.13_eslint_152e84da572378267d0107b5d14531e0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_7ZZmuX4tbM from "/var/www/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_z1bL1s9fMG from "/var/www/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_T2KY3CCoBV from "/var/www/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_Un6zyrErRY from "/var/www/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_Vz73nzBCMh from "/var/www/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.5.4_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_m4KTvhBHJT from "/var/www/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5yAIwKdGj0 from "/var/www/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _00_datadog_client_oBfMsBvh4D from "/var/www/apps/phoenix/nuxt/plugins/00.datadog.client.ts";
import _01_urql_b9cmdWz5Xy from "/var/www/apps/phoenix/nuxt/plugins/01.urql.ts";
import _02_loadConfig_RK9zG7A5iq from "/var/www/apps/phoenix/nuxt/plugins/02.loadConfig.ts";
import _04_auth_b3V54MNNLz from "/var/www/apps/phoenix/nuxt/plugins/04.auth.ts";
import _05_analytics_BKRvirLbPf from "/var/www/apps/phoenix/nuxt/plugins/05.analytics.ts";
import _06_appAssets_dOxy1bcLa6 from "/var/www/apps/phoenix/nuxt/plugins/06.appAssets.ts";
import _07_featureFlagsProvider_wvQWIS5tbq from "/var/www/apps/phoenix/nuxt/plugins/07.featureFlagsProvider.ts";
import gsap_client_YCLjR5XmPz from "/var/www/apps/phoenix/nuxt/plugins/gsap.client.ts";
export default [
  payload_plugin_FEtdNsd8ik,
  revive_payload_client_BsulduVHvU,
  unhead_vTeYUOQtmo,
  router_Fxw36GxM2U,
  _0_siteConfig_kUebwhLlgt,
  _03_seo_fZau0DgIwP,
  payload_client_MagVgg7EQV,
  navigation_repaint_client_WQmv81hSn8,
  check_outdated_build_client_XSrcKMp7aU,
  chunk_reload_client_Vc2AwskmKx,
  plugin_vue3_Czj6bdTzh0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hhhXyzjlVu,
  siteConfig_7ZZmuX4tbM,
  inferSeoMetaPlugin_z1bL1s9fMG,
  titles_T2KY3CCoBV,
  defaultsWaitI18n_Un6zyrErRY,
  i18n_Vz73nzBCMh,
  switch_locale_path_ssr_m4KTvhBHJT,
  i18n_5yAIwKdGj0,
  _00_datadog_client_oBfMsBvh4D,
  _01_urql_b9cmdWz5Xy,
  _02_loadConfig_RK9zG7A5iq,
  _04_auth_b3V54MNNLz,
  _05_analytics_BKRvirLbPf,
  _06_appAssets_dOxy1bcLa6,
  _07_featureFlagsProvider_wvQWIS5tbq,
  gsap_client_YCLjR5XmPz
]