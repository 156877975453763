/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
import UnleashClientSideProvider from '~/utils/featureFlags/unleash/ClientSideProvider';
import MockProvider from '~/utils/featureFlags/MockProvider';
import type { FeatureFlags, FeatureFlagsProvider } from '~/types/featureFlagsProvider';
import { getBootstrapFeatureFlags } from '~/utils/featureFlags/bootstrap';
import type { RuntimeConfig } from 'nuxt/schema';

let serverFeatureFlagProvider: FeatureFlagsProvider;

const getMockProvider = async (runtimeConfig: RuntimeConfig, bootstrap: FeatureFlags): Promise<FeatureFlagsProvider> => {
  const featureFlagsProvider = new MockProvider(runtimeConfig, bootstrap);
  await featureFlagsProvider.init();
  console.log('Feature Flags mock provider initialised');
  return featureFlagsProvider;
};

const getUnleashProvider = async (runtimeConfig: RuntimeConfig): Promise<FeatureFlagsProvider | null> => {
  try {
    // Client side
    if (import.meta.client) {
      const browserFeatureFlagProvider = new UnleashClientSideProvider(runtimeConfig);
      await browserFeatureFlagProvider.init();
      return browserFeatureFlagProvider;
    }
  } catch (error) {
    console.error('Caught Unleash general error on browser, falling back to mock provider:', error);
    return null;
  }

  try {
    // Server side
    if (serverFeatureFlagProvider) {
      return serverFeatureFlagProvider;
    }

    const { default: UnleashServerSideProvider } = await import('~/utils/featureFlags/unleash/ServerSideProvider');

    const featureFlagProvider = new UnleashServerSideProvider(runtimeConfig);
    await featureFlagProvider.init();
    serverFeatureFlagProvider = featureFlagProvider;
    return serverFeatureFlagProvider;
  } catch (error) {
    console.error('Caught Unleash general error on server, falling back to mock provider:', error);
    return null;
  }
};

export default defineNuxtPlugin({
  name: 'feature-flags-provider',
  dependsOn: ['analytics-service', 'auth'],
  setup: async () => {
    const runtimeConfig = useRuntimeConfig();

    let featureFlagsProvider: FeatureFlagsProvider;

    const bootstrap = useState<FeatureFlags>('feature-flags-bootstrap', () => ({}));

    if (import.meta.server) {
      bootstrap.value = await getBootstrapFeatureFlags();
    }

    if (runtimeConfig.public.featureFlagsServiceEnabled) {
      featureFlagsProvider = (await getUnleashProvider(runtimeConfig)) || (await getMockProvider(runtimeConfig, bootstrap.value));
    } else {
      featureFlagsProvider = await getMockProvider(runtimeConfig, bootstrap.value);
    }

    return {
      provide: { featureFlagsProvider },
    };
  },
});
