<script lang="ts" setup>
import type { NavItem } from '@year13/ui';

interface Props {
  navItems: NavItem[];
  footerItems: NavItem[];
  hasMargins?: boolean;
  isManage?: boolean;
}

withDefaults(defineProps<Props>(), {
  hasMargins: true,
  isManage: false,
});

const { isNavOpen } = storeToRefs(useUiStore());
const { globalStudentRole, globalTeacherRole } = useAuth();

const overlayClasses = computed(() => {
  return twMerge(
    cva('fixed inset-0 z-40 bg-black/50 transition-opacity duration-300', {
      variants: {
        isNavOpen: {
          true: 'pointer-events-auto opacity-100',
          false: 'pointer-events-none opacity-0',
        },
      },
    })({
      isNavOpen: isNavOpen.value,
    }),
  );
});
</script>

<template>
  <div class="relative isolate flex h-dvh flex-col overflow-hidden md:flex-row">
    <UISkipToMainContentButton :text="$t('general.skipToMainContent')" href="#main-content" />
    <UIGradientBackground position="top-right" twGradientClasses="from-primary-500/70 to-primary-500/70" />
    <UIGradientBackground position="top-left" twGradientClasses="from-primary-500/70 to-primary-500/70" />
    <nav v-if="!isManage" class="fixed left-3 top-3 z-50 mb-3">
      <button
        :aria-label="isNavOpen ? 'close navigation' : 'open navigation'"
        :aria-expanded="isNavOpen"
        data-testid="sidebar"
        class="flex size-[46px] items-center justify-center rounded-full bg-white shadow-sm"
        @click="isNavOpen = !isNavOpen"
      >
        <UIAssetIcon path="burger-menu" />
      </button>
      <UISidebar :navItems="navItems" :footerItems="footerItems" :open="isNavOpen" @close="isNavOpen = false">
        <template v-if="globalStudentRole?.isManageReferred || globalTeacherRole" #customFooterComponents>
          <CoreAccessManage />
        </template>
      </UISidebar>
    </nav>
    <UINavigationSideDesktop
      v-if="isManage"
      v-model:open="isNavOpen"
      :isOpen="isNavOpen"
      :footerItems="footerItems"
      :navItems="navItems"
      class="hidden md:block"
    >
      <template #logo>
        <NuxtLink aria-label="dashboard" to="/dashboard">
          <CoreClusterLogo variant="iconmarkLight" />
        </NuxtLink>
      </template>
    </UINavigationSideDesktop>
    <div id="layoutScrollContainer" :class="{ 'px-2 md:px-4': hasMargins }" class="h-dvh w-full items-start overflow-y-auto">
      <div :class="{ container: hasMargins }">
        <UINavigationTopMobile v-if="isManage" v-model:open="isNavOpen" :footerItems="footerItems" :navItems="navItems" class="block md:hidden" />
        <main id="main-content" :class="{ 'my-5 p-2 sm:my-12 md:p-4': hasMargins }">
          <div :class="overlayClasses" @click="isNavOpen = false" />
          <slot name="top-content" />
          <Suspense>
            <slot />
            <template #fallback>
              <UICenteredContainer>
                <UILoader size="lg" />
              </UICenteredContainer>
            </template>
          </Suspense>
        </main>
      </div>
    </div>
    <slot name="extras" />
    <CoreSessionUnAuthedModal />
  </div>
</template>
