<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed, resolveComponent } from 'vue';

interface Props {
  to?: string | object | null;
  variant?: 'primary' | 'outlined' | 'no-style' | 'danger' | 'secondary' | 'success' | 'link';
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit';
  disabled?: boolean;
  customClasses?: string;
  iconPath?: string | null;
  iconPosition?: 'left' | 'right';
  text?: string;
  textVisible?: boolean | null;
  loading?: boolean;
  isExternalLink?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  to: null,
  variant: 'primary',
  size: 'md',
  type: 'button',
  disabled: false,
  iconPath: null,
  iconPosition: 'left',
  customClasses: '',
  textVisible: true,
  text: '',
  loading: false,
  isExternalLink: false,
});

const emit = defineEmits<{
  click: [e: MouseEvent];
}>();

const variantClasses = computed<string>(() => {
  return twMerge(
    cva('ease inline-block rounded-lg text-center tracking-wider delay-75 duration-75', {
      variants: {
        variant: {
          primary: 'w-full border-2 border-secondary-500 bg-secondary-500 font-bold text-white sm:w-[initial]',
          'no-style': 'font-normal',
          outlined: 'w-full border-2 font-bold text-secondary-500 sm:w-[initial]',
          danger: 'border-2 border-red-500 bg-red-500 font-bold text-white',
          secondary: 'font-bold',
          success: 'border-2 border-green-500 bg-green-500 font-bold text-white',
          link: 'font-semibold text-primary-500',
        },
        size: {
          lg: 'px-8 py-4 text-base',
          md: 'px-6 py-3 text-base',
          sm: 'px-4 py-2 text-sm',
          xs: 'px-3 py-1.5 text-sm',
          '2xs': 'px-2 py-1',
        },
        disabled: {
          true: 'cursor-not-allowed border-opacity-50 opacity-50',
        },
        loading: {
          true: 'relative text-opacity-0 opacity-60',
        },
      },
      compoundVariants: [
        { variant: 'primary', disabled: true, class: 'border-secondary-200 bg-secondary-200' },
        { variant: 'outlined', disabled: true, class: 'border-secondary-200 text-secondary-200' },
        { variant: 'danger', disabled: true, class: 'border-red-200 bg-red-200' },
        { variant: 'link', disabled: true, class: 'text-primary-200' },
        { variant: 'primary', disabled: false, class: 'hover:bg-secondary-600' },
        { variant: 'danger', disabled: false, class: 'hover:border-red-600 hover:bg-red-600' },
        { variant: 'link', disabled: false, class: 'hover:text-primary-600' },
        { variant: 'no-style', class: 'p-0' },
      ],
    })({
      size: props.size,
      variant: props.variant,
      disabled: props.disabled,
      loading: props.loading,
    }),
    props.customClasses,
  );
});

const loadingVariantClasses = computed<string>(() => {
  return twMerge(
    cva('', {
      variants: {
        variant: {
          primary: 'text-white',
          outlined: 'text-primary-500',
          secondary: 'text-white',
          danger: 'text-white',
          success: 'text-white',
          'no-style': 'text-primary-500',
          link: 'text-primary-500',
        },
      },
    })({
      variant: props.variant,
    }),
  );
});

const nuxtLink = resolveComponent('NuxtLink');
const linkRel = computed(() => (props.isExternalLink ? 'noopener noreferrer nofollow' : undefined));

const onClick = (event: MouseEvent) => {
  if (props.disabled || props.loading) {
    return;
  }

  emit('click', event);
};

const toHref = computed(() => (props.disabled ? undefined : props.to));
</script>

<template>
  <component
    :is="to ? nuxtLink : 'button'"
    :aria-disabled="disabled || loading"
    :aria-label="text"
    :class="variantClasses"
    :external="to ? isExternalLink : undefined"
    :rel="linkRel"
    :target="isExternalLink ? '_blank' : undefined"
    :to="toHref"
    :type="to ? undefined : type"
    @click="onClick"
  >
    <div class="flex items-center justify-center gap-2">
      <UIAssetIcon
        v-if="iconPath"
        :class="{ 'order-1': iconPosition === 'left', 'order-2': iconPosition === 'right' }"
        :path="iconPath"
        :size="size"
      />
      <template v-if="text && textVisible">
        <span :class="{ 'order-2': iconPosition === 'left', 'order-1': iconPosition === 'right' }">{{ text }}</span>
      </template>
      <div v-if="loading" class="absolute flex items-center justify-center">
        <UILoader :class="loadingVariantClasses" :size="size" variant="basic" />
      </div>
    </div>
  </component>
</template>
