import type { RuntimeConfig } from 'nuxt/schema';
import type { FeatureFlags, FeatureFlagsProvider, Variant } from '~/types/featureFlagsProvider';

export default class MockProvider implements FeatureFlagsProvider {
  private flags: Map<string, boolean | Variant> = new Map();

  constructor(_runtimeConfig: RuntimeConfig, bootstrap: FeatureFlags) {
    this.mockBootstrap(bootstrap);
  }

  async init(): Promise<void> {
    return await Promise.resolve();
  }

  async getFlag(flagName: string): Promise<boolean> {
    return await Promise.resolve(<boolean>this.flags.get(flagName) || false);
  }

  async getVariant(flagName: string): Promise<Variant> {
    return await Promise.resolve(<Variant>this.flags.get(flagName));
  }

  getEvaluatedFlags(): FeatureFlags {
    return Object.fromEntries(this.flags);
  }

  private mockBootstrap(bootstrap: FeatureFlags): void {
    for (const [flagName, value] of Object.entries(bootstrap)) {
      this.flags.set(flagName, value);
    }
  }
}
