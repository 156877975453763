<script setup lang="ts">
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed } from 'vue';

const { switchToSchool, currentSchoolId, schoolOperationalRoles } = useAuth();
const configStore = useConfigStore();

const clusterRoles = computed(() => schoolOperationalRoles.value.filter((r) => r.school?.clusterId === configStore.cluster?.id));

const checkboxClasses = (selected: boolean) => {
  return twMerge(
    cva('flex size-6 items-center justify-center rounded-full border border-grey-400 bg-white p-1', {
      variants: {
        selected: {
          true: 'border-green-400 bg-green-50 text-green-400',
        },
      },
    })({ selected }),
  );
};

const rowClasses = (selected: boolean, isLast: boolean) => {
  return twMerge(
    cva('grid w-full grid-cols-12 items-center gap-x-4 break-words bg-grey-50 p-3 font-medium hover:bg-primary-50 focus:bg-primary-50', {
      variants: {
        selected: {
          true: 'bg-primary-50 bg-opacity-50',
        },
        isLast: {
          true: 'rounded-b-lg',
        },
      },
    })({ selected, isLast }),
  );
};

const isSelectedSchool = (schoolId?: string) => currentSchoolId.value === schoolId;
const selectSchool = (close: () => void, id?: string) => {
  if (!id) {
    return;
  }
  void switchToSchool(id);
  close();
};

const currentSchool = computed(() => clusterRoles.value.find((r) => r.school?.id === currentSchoolId.value)?.school);
</script>

<template>
  <div class="relative">
    <template v-if="clusterRoles.length > 1">
      <UIPopover>
        <template #anchor="{ close }">
          <button
            type="button"
            class="flex items-center text-primary-500"
            :aria-label="$t('general.switchSchool.label')"
            data-testid="change-school"
            @keydown.esc="close()"
          >
            <UIHeading size="h1" styleSize="h4" customClasses="sm:text-2xl text-lg"> {{ currentSchool?.name || '' }}</UIHeading>
            <UIAssetIcon path="chevron-down-full" size="xs" class="ml-1 flex fill-primary-500" />
          </button>
        </template>
        <template #content="{ close }">
          <UICard role="dialog" aria-modal="true" customClasses="bg-white rounded-lg px-0 py-0 w-56" padding="none" @keydown.esc="close()">
            <template #default>
              <div class="flex w-full items-center p-3">
                <div class="font-bold">{{ $t('general.switchSchool.title') }}</div>
              </div>
              <ul>
                <li v-for="(role, index) in clusterRoles" :key="role.id" class="w-full">
                  <button
                    type="button"
                    :class="rowClasses(isSelectedSchool(role.school?.id), index === clusterRoles.length - 1)"
                    :aria-label="role.school?.name"
                    @click="selectSchool(close, role.school?.id)"
                  >
                    <div class="col-span-9 text-left">
                      <span>{{ role.school?.name }}</span>
                    </div>
                    <div class="col-span-3 flex items-center justify-end">
                      <div :class="checkboxClasses(isSelectedSchool(role.school?.id))">
                        <UIAssetIcon v-if="isSelectedSchool(role.school?.id)" path="check" size="xs" />
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
            </template>
          </UICard>
        </template>
      </UIPopover>
    </template>
    <template v-else>
      <UIHeading size="h1" styleSize="h4" customClasses="sm:text-2xl text-lg"> {{ currentSchool?.name || '' }}</UIHeading>
    </template>
  </div>
</template>
