import { graphql } from '~/graphql/generated/gql';

export const shallowUserActivityGroupFragment = graphql(`
  fragment shallowUserActivityGroupFields on UserActivityGroup {
    id
    order
    status
    activityGroup {
      id
      name
      type
    }
    userActivities {
      ...shallowUserActivityFields
    }
  }
`);

export const shallowUserActivityFragment = graphql(`
  fragment shallowUserActivityFields on UserActivity {
    id
    status
    order
    skipped_at
    activity {
      id
      title
      type
      config {
        systemConfig {
          ...systemConfigFields
        }
        activityInitialValue {
          ...activityInitialValueUnionFields
        }
      }
    }
  }
`);

export const deepUserActivityFragment = graphql(`
  fragment deepUserActivityFields on UserActivity {
    id
    status
    result
    skipped_at
    userActivityResponses {
      id
      key
      response
    }
    activity {
      id
      type
      title
      config {
        systemConfig {
          ...systemConfigFields
        }
        activityConfig {
          ...activityConfigUnionFields
        }
        activityInitialValue {
          ...activityInitialValueUnionFields
        }
      }
    }
  }
`);

export const GET_USER_ACTIVITY_BY_IDS = graphql(`
  query getUserActivityById($ids: [ID!]!) {
    core {
      user {
        self {
          id
          userActivities(ids: $ids) {
            ...deepUserActivityFields
          }
        }
      }
    }
  }
`);

export const SAVE_USER_ACTIVITY_RESPONSE = graphql(`
  mutation saveUserActivitiesResponse($userActivityId: ID!, $key: String!, $jsonResponse: JSON!) {
    activities {
      saveResponse(userActivityId: $userActivityId, key: $key, jsonResponse: $jsonResponse) {
        success
        message
        userActivity {
          ...deepUserActivityFields
        }
        userActivityGroup {
          id
          status
        }
      }
    }
  }
`);

export const SAVE_USER_ACTIVITY_COMPLETE = graphql(`
  mutation saveUserActivitiesComplete($userActivityId: ID!, $jsonPayload: JSON!) {
    completeActivity(userActivityId: $userActivityId, jsonPayload: $jsonPayload) {
      success
      message
      userActivity {
        ...deepUserActivityFields
      }
      userActivityGroup {
        id
        status
      }
      updatedUserActivityGroups {
        ...shallowUserActivityGroupFields
      }
      updatedUserWalletItemValues {
        ...walletItemValueFields
      }
    }
  }
`);

export const SKIP_USER_ACTIVITY = graphql(`
  mutation skipUserActivity($userActivityId: ID!) {
    activities {
      skipActivity(userActivityId: $userActivityId) {
        success
        message
        userActivity {
          ...shallowUserActivityFields
        }
        userActivityGroup {
          id
          status
        }
      }
    }
  }
`);
