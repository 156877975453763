<script lang="ts" setup>
interface Props {
  variant?: 'default' | 'surface' | 'glassy' | 'primary' | 'blue';
  shape?: 'none' | 'default' | 'chat' | 'card' | 'widget';
  shadow?: 'none' | 'xs' | 'sm' | 'md' | 'lg';
  padding?: 'none' | 'sm' | 'md-even' | 'md' | 'lg' | 'xl' | '2xl';
  isButton?: boolean;
  customClasses?: string;
  overflow?: 'visible' | 'hidden';
  borderType?: 'dashed' | 'none' | 'solid';
  borderColor?: 'grey' | 'secondary' | 'blue';
  backgroundColor?: 'blue' | 'none' | 'transparent' | 'purple';
  hasScroll?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'default',
  shape: 'default',
  shadow: 'md',
  isButton: false,
  customClasses: '',
  padding: 'md',
  overflow: 'visible',
  borderColor: 'grey',
  borderType: 'none',
  backgroundColor: 'none',
  hasScroll: false,
});

const cardType = computed<'button' | 'div'>(() => (props.isButton ? 'button' : 'div'));

const variantClasses = computed<string>(() => {
  return twMerge(
    cva('', {
      variants: {
        variant: {
          default: 'border border-grey-100 bg-white',
          surface: 'bg-white bg-opacity-80 drop-shadow-lg backdrop-blur-sm',
          glassy: 'rounded-xl bg-white/40 drop-shadow-[0_6px_6px_rgba(2,0,98,0.15)] backdrop-blur-xl',
          primary: 'bg-primary-50/50',
          blue: 'border bg-blue-50',
        },
        backgroundColor: {
          blue: 'bg-blue-50',
          none: '',
          transparent: 'bg-transparent',
          purple: 'bg-purple-500',
        },
        borderType: {
          dashed: 'border-dashed',
          solid: 'border border-grey-100',
          none: '',
        },
        borderColor: {
          grey: 'border-grey-100',
          secondary: 'border-secondary-300',
          blue: 'border-blue-200',
        },
        overflow: {
          visible: 'overflow-visible',
          hidden: 'overflow-hidden',
        },
        padding: {
          none: 'p-0',
          sm: 'p-4',
          'md-even': 'p-6',
          md: 'p-2 py-4 md:px-4 md:py-6',
          lg: 'px-2 py-6 md:p-8',
          xl: 'px-2 py-6 md:px-14 md:py-11',
          '2xl': 'px-8 py-6 md:px-14 md:py-11',
        },
        shape: {
          default: 'rounded-2xl',
          none: 'rounded-none',
          chat: 'rounded-r-3xl rounded-bl-3xl rounded-tl-none',
          card: 'rounded-lg',
          widget: 'rounded-2xl',
        },
        shadow: {
          none: '',
          md: 'shadow-md',
          lg: 'shadow-lg',
          sm: 'shadow-sm',
          xs: 'shadow-xs',
        },
      },
      compoundVariants: [
        {
          borderType: 'dashed',
          borderColor: 'grey',
          class: 'border-2 border-grey-100',
        },
        {
          borderType: 'dashed',
          borderColor: 'secondary',
          class: 'border border-secondary-300',
        },
        {
          borderType: 'solid',
          borderColor: 'grey',
          class: 'border-2 border-grey-100',
        },
        {
          borderType: 'solid',
          borderColor: 'secondary',
          class: 'border-2 border-secondary-300',
        },
        {
          padding: 'xl',
          shape: 'widget',
          class: 'px-6 py-8 md:px-[55px] md:py-[44px]',
        },
      ],
    })({
      variant: props.variant,
      shape: props.shape,
      shadow: props.shadow,
      overflow: props.overflow,
      padding: props.padding,
      borderColor: props.borderColor,
      borderType: props.borderType,
      backgroundColor: props.backgroundColor,
    }),
    props.customClasses,
  );
});

const slotVariantClasses = computed(() => {
  return twMerge(
    cva('size-full', {
      variants: {
        hasScroll: {
          true: 'overflow-y-auto [&::-webkit-scrollbar-thumb]:rounded-2xl [&::-webkit-scrollbar-thumb]:bg-blue-400 [&::-webkit-scrollbar-track]:rounded-2xl [&::-webkit-scrollbar-track]:bg-white [&::-webkit-scrollbar]:w-1.5',
          false: '',
        },
      },
    })({
      hasScroll: props.hasScroll,
    }),
  );
});
</script>

<template>
  <component :is="cardType" :class="variantClasses">
    <div :class="slotVariantClasses">
      <slot />
    </div>
  </component>
</template>
